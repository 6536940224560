import React from "react"
import { Seo } from "../../components/seo"

const ThankyouEn = () => {
  return (
    <section className="container thankyou-en english-page-body">
      <div className="row">
        <div className="en-pages">
          <p>Thank you so much for applying. We will respond ASAP.</p>
        </div>
      </div>
    </section>
  )
}

export default ThankyouEn

export const Head = () => {
  return (
    <Seo
      title={`Thank you so much for applying. We will respond ASAP | Mexico job vacancies, employment and career change information [QUICK GLOBAL MEXICO]`}
      url={`https://www.919mexico.com/en/thankyou`}
      language="en"
    />
  )
}
